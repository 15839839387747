<template>
    <div class="select-language-father-container mt-2" style="height: 32px;">
        <div class="select-language d-flex flex-column justify-content-center align-content-center cursor-pointer">
            <span class="lang-container d-flex color-light-gray-50">
                <span class="color-light-gray-50 text-center w-100 ">
                    {{ $i18n.locale.toUpperCase() }}
                </span>
            </span>
            <div class="red-underline" style="width: 18px;">
                <icon-language style="transform: translateY(3px)" :color="'#fff'" :size="18"/>    
            </div>
        </div>
            <div class="lang-icon-container caret-down-triangle d-flex align-self-end position-relative ms-2" style="height: 21px;">
                <icon-caret-down :size="10"/>
            </div>
        <sub-menu-container class="select-language-container">
            <div class="d-flex flex-column">
                <div class="d-flex">
                    <h5 class="title-16 mb-0">{{ $t("layout.select_language") }}</h5>                
                </div>
                <hr class="submenu-divider mb-0 mt-1"/>
                <div class="mt-2">
                    <label class="language-selector d-flex cursor-pointer rounded w-100 cursor-pointer position-relative" :key="`lang${i}`" @click.prevent="changeLanguage(lang.key)" :value="lang.key" v-for="(lang, i) in langs" :class="[$i18n.locale === lang.key ? 'active' : 'inactive']">
                        <span class="language-icon">
                            <icon-american-flag :size="23" class="mr-2" alt="iconLanguage" v-if="i === 1" /> 
                            <icon-dominican-flag :size="23" class="mr-2" alt="iconLanguage" v-if="i === 0" />
                        </span>
                        <span class="language-title desc-13" id="langSelected">{{ lang.language }}</span>
                        <input class="language-check opacity-0" type="radio" name="radio" :checked="$i18n.locale === lang.key" />
                        <icon-check-mark class="language-checkmark" :size="12" />
                    </label>
                    <div class="selected-bg position-absolute" style="background-color: #DFE6ED73;"></div>
                </div>
            </div>
        </sub-menu-container>
    </div>
</template>

<script>
export default {
    name: 'SelectLanguage',
    components: {
        IconCaretDown: () => import('@/common/svg/iconCaretDown'),
        IconLanguage: () => import('@/common/svg/iconLanguage'),
        IconCheckMark: () => import('@/common/svg/iconCheckMark'),
        //flags
        IconAmericanFlag: () => import('@/common/svg/iconAmericanFlag'),
        IconDominicanFlag: () => import('@/common/svg/iconDominicanFlag'),

        //submenu container
        SubMenuContainer: () => import('@/modules/layout/components/navbar/menus/submenus/SubMenuContainer.vue'),
    },
    data(){
        return {}
    },
    methods: {
        async changeLanguage(lang){
            setImmediate(() => { this.$i18n.locale = lang});
            localStorage.setItem("lang", lang)
        }
    },
    computed:{
        langs() {
            return [
                { language: this.$t('common.language.ES'), key: 'es', isActive: true },
                { language: this.$t('common.language.EN'), key: 'en', isActive: false },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.menu-item {
    &:hover {
        @media screen and (min-width: $lg) {
            .sub-menu-container {
                display: block;
            }
        }
    }
    svg {
        fill: white;
    }
}

.select-language-father-container{
    &:before{
        width: 50px;
    }
    width: 36px;
}

/* will be exported as a common component */
.lang-container {
    font-family: 'Rubik' !important;
    font-size: 10px !important; 

    line-height: 0.2;
    padding-top: 1px;
}

.select-language {
    margin-top: -2px;
    height: 32px;
    //white-space
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -2px;
        
        height: 55px;
        width: 80px;

        cursor: pointer;
    }
}

.select-language-container {
    width: 218px;
    left: -2px;

    .language-selector {
        transition: $transition-0 !important;
        padding: 4px;
        background: transparent;
        z-index: 13;
        &:first-child{
            margin-bottom: 4px;
        }
        .language-icon {
            margin-right: 6px;
            z-index: 13;
        }
        .language-title{
            line-height: 28px;
            margin-right: auto;
            z-index: 13;
        }
        .language-checkmark {
            transition: $transition-0 !important;
            margin-right: 10px;
            opacity: 1;
            z-index: 13;
            fill: var(--blue-700)
        }
    }

    /* language selected */
    .active {
        transition: all 500ms ease-in-out !important;
        span {
            transition: $transition-0 !important;
            color: $dark-main-navy;
        }
        & + .selected-bg {
            transform: translateY(-36px);
        }
    }
    /* languages not selected */
    .inactive {        
        & + .selected-bg {
            transform: translateY(-76px);
        }
        transition: $transition-0 !important;
        .language-checkmark {
            opacity: 0;
        }
        .language-icon {
            transition: $transition-0 !important;
            opacity: 0.5;
        }
        &:hover {
            background-color: #F4F7F9BF;
            span {
                transition: $transition-0 !important;
                color: $dark-main-navy;
            }
            .language-icon {
                transition: $transition-0 !important;
                opacity: 1;
            }
        }
    }
    .selected-bg {  
        transition: all .3s ease-in-out;
        width: 187px;
        height: 36px;
        z-index: 12;
        border-radius: .25rem !important;
}
    transition: $transition-2;
}

/* arrow icon */
.arrow {
    position: absolute;
    top: 8px;
    right: 10px;

    height: 12px;
    width: 12px;
    
    opacity: 0;
    fill:#212E3D
}
</style>